/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import styled from "styled-components"
import Container from "@components/Container"
import theme, { rem } from "@src/theme"
import Pill from "@components/Pill"
import ImageBannerCard from "@components/ImageBannerCard"
import ArticleFooter from "@components/ArticleFooter"
import MarkdownBody from "@modules/MarkdownBody"

const ArticlePost = ({ image, title, tags, date, html, path, linkPrefix = 'articles' }) => (
  <Container
    sx={{
      width: "100%",
      maxWidth: ["100%", "90%", rem(880)],
    }}
  >
    <ImageBannerCard image={image} title={title}>
      <MetaInfo
        sx={{
          flexDirection: ["column-reverse", null, "row"],
          alignItems: ["start", null, "center"],
        }}
      >
        {tags && tags.length ? (
          <TagsList>
            {tags.slice(0, 3).map(tag => (
              <li sx={{ mr: 2, mb: [1, 0] }} key={tag}>
                <Link to={`/${linkPrefix}?tag=${tag}`}>
                  <Pill key={tag}>{tag}</Pill>
                </Link>
              </li>
            ))}
          </TagsList>
        ) : null}

        <p className="date">{date}</p>
      </MetaInfo>
      <MarkdownBody html={html} />
      <ArticleFooter
        path={path}
        title={title}
        shareHeading="Share this article"
      />
    </ImageBannerCard>
  </Container>
)

export default ArticlePost

const TagsList = styled.ul`
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  justify-content: center;
  a {
    text-decoration: none;

    &:hover {
      .pill {
        background-color: ${theme.colors.darkPurple};
        color: ${theme.colors.white};
      }
    }
  }
`

const MetaInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.space[6]};
  .date {
    margin: 0;
  }
`
