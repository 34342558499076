/** @jsx jsx */
import { jsx } from "theme-ui"
import { rem } from "@src/theme"

export default function ({
  active,
  onClick,
  children,
  color = "darkPurple",
  style,
  largeVariant = false,
}) {
  return (
    <div
      className="pill"
      role="button"
      onClick={e => {
        e.stopPropagation()
        onClick()
      }}
      sx={{
        color: color,
        height: [rem(28), rem(32)],
        display: "inline-flex",
        alignItems: "center",
        border: "2px solid",
        borderColor: color,
        backgroundColor: active ? "currentColor" : "transparent",
        px: 2,
        py: largeVariant ? 2 : 'unset',
        borderRadius: 100,
        textAlign: "center",
        whiteSpace: "nowrap",
        cursor: "pointer",
        ':hover': {
          backgroundColor: active ? "currentColor" : "rgba(0,0,0,0.25)",
        },
        ...style,
      }}
    >
      <span
        sx={{
          fontSize: largeVariant ? [rem(18), rem(20)] : [rem(14), rem(16)],
          fontWeight: "bold",
          transform: "translateY(-1.5px)",
          color: active ? "white" : "currentColor",
        }}
      >
        {children}
      </span>
    </div>
  )
}
