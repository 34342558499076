/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import { Grid } from "@theme-ui/components"
import { rem } from "@src/theme"

import Container from "@components/Container"
import Layout from "@components/Layouts"
import Banner from "@components/Banner"
import ArticleCard from "@components/ArticleCard"
import SEO from "@components/seo"
import ArticlePost from "@components/ArticlePost"
import Section from "@components/Section"
import { getSlug } from "@helpers/utils/getSlug"

export default function Template({ data, ...props }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const {
    title,
    date,
    image,
    tags,
    cardPreview,
    ogTitle,
    ogdescription,
    ogimage,
  } = frontmatter
  const {
    relatedArticles,
    linkPrefix = "articles",
    itemType,
    listingPage,
  } = props.pageContext

  return (
    <Layout>
      <SEO
        title={ogTitle || title}
        image={ogimage || image}
        pathname={props.location.pathname}
        description={ogdescription || cardPreview}
      />
      <Section
        noPb
        className="pink-linear"
        sx={{
          bg: "pink",
          color: "darkPurple",
        }}
      >
        <ArticlePost
          image={image}
          title={title}
          tags={tags}
          date={date}
          html={html}
          path={props.path}
          linkPrefix={linkPrefix}
        />

        {relatedArticles && relatedArticles.length ? (
          <section
            sx={{
              bg: "pink",
              mt: 10,
              pt: 6,
              pb: 12,
            }}
          >
            <Container>
              <div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: ["column", null, "row"],
                  mb: 7,
                }}
              >
                <div
                  sx={{
                    mb: [rem(20), null, 0],
                  }}
                >
                  <Banner title="Related Articles" />
                </div>

                <Link
                  to={listingPage ? `/${listingPage}` : `/${linkPrefix}`}
                  sx={{
                    fontFamily: "heading",
                    color: "currentColor",
                    fontWeight: "900",
                  }}
                >
                  Browse all {itemType ? `${itemType}s` : "Articles"}
                </Link>
              </div>

              <Grid gap={rem(32)} columns={[1, 2, 3, 4]}>
                {relatedArticles.map(article => (
                  <ArticleCard
                    linkPrefix={linkPrefix}
                    key={article.node.id}
                    data={{
                      ...article,
                      slug: getSlug(article),
                      preview: article.node.frontmatter.cardPreview,
                    }}
                  />
                ))}
              </Grid>
            </Container>
          </section>
        ) : null}
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        cardPreview
        tags
        image
        ogTitle
        ogdescription
        ogimage
      }
    }
  }
`
