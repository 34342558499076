/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import { navigate } from "@reach/router"
import * as _ from "lodash"
import slugify from "slugify"
import theme, { rem } from "@src/theme"
import Pill from "@components/Pill"

const ArticleCard = ({ data, linkPrefix = "articles" }) => {
  const onCardClick = () => navigate(`/${linkPrefix}/${data.slug}`)
  const onPillClick = () => {
    navigate(`/${linkPrefix}/?tag=${slugify(data.node.frontmatter.tags[0])}`)
  }

  const handleCardKeyDown = e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      onCardClick()
    }
  }
  return (
    <Outer
      tabIndex="0"
      onClick={onCardClick}
      onKeyDown={handleCardKeyDown}
      sx={{
        display: "block",
        textDecoration: "none",
        height: "100%",
        bg: "white",
      }}
      role="button"
    >
      <figure
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: 0,
          color: "currentColor",
          height: "100%",
          position: "relative",
          zIndex: 1,
        }}
      >
        <img
          src={data.node.frontmatter.image}
          alt=""
          sx={{
            height: rem(200),
            objectFit: "cover",
            width: "100%",
            zIndex: -2,
          }}
        />
        <div
          sx={{
            p: rem(30),
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "baseline",
            pt: rem(10),
            pb: rem(10)
          }}
        >
          <div>
            {data.node.frontmatter?.tags?.length > 0 && <figcaption sx={{ mb: rem(10), textTransform: 'capitalize', fontSize: rem(15) }}>{data.node.frontmatter.tags[0]}</figcaption>}
            <figcaption
              className="article-card__title"
              sx={{
                variant: "typography.heading",
                fontSize: [rem(22)],
                fontWeight: 900,
                lineHeight: 1.4,
                color: "currentColor",
              }}
            >
              {data.node.frontmatter.title}
            </figcaption>
            <p
              sx={{
                fontSize: rem(15),
                color: "currentColor",
              }}
            >
              {data.preview}
            </p>
          </div>
        </div>
      </figure>
    </Outer>
  )
}

export default ArticleCard

const Outer = styled.div`
  cursor: pointer;
  position: relative;
  z-index: 1;

  &:hover,
  &:active,
  &:focus {
    background-color: ${theme.colors.lightGrey};
    transition: background-color 300ms ease, color 300ms ease;
    .pill {
      color: ${theme.colors.white};
      background-color: ${theme.colors.darkPurple};
      border-color: ${theme.colors.white};
      transition: all 300ms ease;
    }
  }
`
