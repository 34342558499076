/** @jsx jsx */
import { jsx } from "theme-ui"
import { CenteredAbsoluteBanner } from "@components/Banner"
import { rem } from "@src/theme"

const ImageBannerCard = ({ image, title, children }) => {
  return (
    <div sx={{ bg: "white", pb: 8 }}>
      <div sx={{ position: "relative", mb: 9 }}>
        {image && (
          <img
            src={image}
            alt=""
            sx={{
              height: [rem(300), null, rem(519)],
              objectFit: "cover",
              width: "100%",
              mb: 5,
            }}
          />
        )}

        <CenteredAbsoluteBanner title={title} fontSize={[rem(20), rem(40)]} />
      </div>

      <div
        sx={{
          width: ["90%", null, "80%"],
          margin: "0 auto",
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default ImageBannerCard
