/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import theme, { rem } from "@src/theme"
import Twitter from "@components/assets/svg/twitter.svg"
import Facebook from "@components/assets/svg/facebookBlock.svg"

const ArticleFooter = ({ path, title, shareHeading = "Share" }) => {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <FooterActions
      sx={{
        flexDirection: ["column", "row"],
        alignItems: ["normal", "center"],
      }}
    >
      <div
        sx={{
          mb: [3, 0],
        }}
      >
        <h3
          sx={{
            mr: 4,
            variant: "typography.heading",
            fontWeight: 900,
            fontSize: rem(22),
            mt: 0,
            mb: [1, 0],
            display: "inline-block",
          }}
        >
          {shareHeading}
        </h3>
        <div sx={{ display: ["block", "inline-block"] }}>
          <a
            sx={{
              color: "darkPurple",
            }}
            className="socials"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://twitter.com/intent/tweet?text=${title}&url=https://minus18.org.au${path}`}
          >
            <Twitter width={rem(18)} sx={{ transform: "translateY(5px)" }} />
            <span>Tweet</span>
          </a>
          <a
            sx={{
              color: "darkPurple",
            }}
            className="socials"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.facebook.com/sharer/sharer.php?u=https://minus18.org.au${path}`}
          >
            <Facebook sx={{ transform: "translate(-2px, 2px)" }} />
            <span>Share</span>
          </a>
        </div>
      </div>

      <button
        className="scroll-action"
        sx={{
          mt: [rem(30), null, 0],
        }}
        onClick={scrollTop}
      >
        Scroll to top
      </button>
    </FooterActions>
  )
}
export default ArticleFooter

const FooterActions = styled.div`
  display: flex;

  justify-content: space-between;

  button.scroll-action {
    cursor: pointer;
    font-weight: 700;
    color: ${theme.colors.darkPurple};
    text-decoration: underline;
  }

  a.socials {
    cursor: pointer;
    margin-right: ${theme.space[4]};
    svg {
    }
    span {
      display: inline-block;
      margin-left: 5px;
      font-weight: 700;
      text-decoration: underline;
    }
  }
`
